import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Virgo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Virgo Nature
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/virgo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Virgo</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Virgo Nature</h4>
              
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Virgo Nature </h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-love");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-man");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
                <p className="playfair text-black text-xl md:text-3xl">Virgo Nature</p><br/>
                Virgo natives are defined as mature men and manipulated. You are the stars and perfectionists of every office. Although some may judge you are bored, you have a fascinating internal world where everything has a place and purpose.
                <br></br>
                <br></br>
                You are powerful but prefer to be conservative, well-organized, and practical in your day-to-day life. You have a proper understanding of the known traits of a human being. You are accommodating, supportive, and pure at heart. 
                <br></br>
                <br></br>
                Although you are often delicate, your soul might be close to the outer world. You have a well-organized life, and your plans and goals always have strictly defined boundaries in your minds. You usually try to prepare everything in advance and put your struggles to reach that goal. You, at times, bear too much stress about your work. 
                <br></br>
                <br></br>
                <p className="playfair text-black text-xl md:text-3xl">Perfection in Personality</p><br/>
                Your need and desire for perfection give you hindrances and risks. You, at times, become overly crucial and concerned about issues that nobody else seems to care much about.
                <br></br>
                <br></br>
                Many of you are engaged in keeping the corners of your mind tidy and orderly. So you become thoughtless about your clothes or surroundings. This may mislead others when they see you in an off moment. Although your goal of very few impossible dreams, you often have the distinctive trait of scrutinizing like lovely dreamers. You can unexpectedly become grumpy, cranky, and hyper. Mostly, you are delicate folks, and people feel pretty lovely to have you around. You are the type of guest who will cheerfully help the hostess clean up after the event. You have prominent blindness to your flaws and seem unable to see your imperfections as evidently as you see other things.
                <br></br>
                <br></br>
                <p className="playfair text-black text-xl md:text-3xl"> Value their Relations</p><br/>
                You are terrific at building relationships with people. Hence, you usually have a big friend group and can be favorably active in the colonial circle. You are very careful about your sentiments and typically don't want to share them in public. Will your communication talents and perfect performance help you succeed in 2024? 
                <br></br>
                <br></br>
                Acquire your personalized free janampatri report from our world's best astrologers!
                <br></br>
                <br></br>
                <p className="font-bold inline-block text-orange-500">Virgo Ruling Planet:</p>Mercury
                <br></br>
                <br></br>
                Mercury's fleet-footed messenger planet rules Virgo natives and makes them smart yet humorous. You have a well-developed feeling of speech and writing and all other forms of communication. You are extremely experienced in managing information. You meticulously narrow down the worth of data by picking out only what's essential and valuable. The power of the; makes you particularly focused and analytical. At the same time, it causes you a credible source of knowledge and thoughts. The sharp sense of understanding and knowledge makes you faithful, reliable, and valuable. You possess the capability to multitask and check off their never-ending to-do lists with accuracy.
                <br></br>
                <br></br>
                <p className="font-bold inline-block text-orange-500">Ruling House:</p>Sixth (The house of routine)
                <br></br>
                The sixth house is a significator of your day-to-day habits, direction to fitness, profession, and idea of personal growth. The house's energy leads to the inclination of the devotion to structure, a penchant for planning, and a compass on progress. 
                <br></br>
                <br></br>
                The sixth house is also connected to your performance and efficiency in the assignments related to work and not necessarily your career. It impacts your duties and commitments regarding your personal life separated from the job, making you your bread and butter. 
                <br></br>
                <br></br>
                This sector is also where you look to seek to learn about health and recovery. It is not only about your work schedule but also about the everyday habits of hygiene – the cleanliness that you give so much significance to.
                <br></br>
                <br></br>
                <p className="playfair text-black text-xl md:text-3xl">Habits</p><br/>
                You are obsessed with cleanliness and systematizing things perfectly. You have the personality who always wants everything to be organized, everything placed on the right side. Due to your perfection bar, you are often anxious about doing things with perfection because imperfections cause stress to you. Also, you get upset and worried if things don't go as per your anticipations. 
                <br></br>
                <br></br>
                You are allergic to obscenity and dirt and try to avoid them. Regardless, you need to carefully understand that it is essential that others feel good about you somewhat of your physical appearance. You are very good at adjusting to your limits and shaping your needs according to your resources. You may even have the routine of offering unsolicited advice. You have a vision for detail for everything but often take it too far.
                <br></br>
                <br></br>
                Sometimes you bring it so far that you get into heated discussions and arguments with others who aren't doing things the way you want them to. You can beat over any disputes, discussions, or controversies, or balance all by purchasing online remedial solutions our experts advise for smooth sailing of connections in personal and professional life.
                <br></br>
                <br></br>
                <p className="font-bold inline-block text-orange-500">Element:</p>Earth
                Element: Earth, The part of Earth that entrusts you to become an influential, loyal person. But you like to be conservative and well-organized in things. You can quickly set plans and make an impeccable construction plan to be made. You are a viewer, possess an analytical mind, and are an efficient person who is acutely conscious of your world, specifically if something is incorrect or out of place. You also have an uncanny power to sense when someone is trying to fool you or deceive you. According to the best astrological principles, you live firmly placed on the ground with your feet. You tend to tolerate only that is true, not what is still in the realms of the imaginary or dreamy world. That is why your recommendations are much in need of others. You concentrate on the facts of the world around you. Despite hardships and disorder in your life, your dreams and goals still have rigorously defined edges in your minds.
                <br></br>
                <br></br>
                <p className="playfair text-black text-xl md:text-3xl">Strengths</p><br/>
                You enjoy learning about all situations in incredible detail. You are genuinely inclined towards comprehending things. 
                <br></br>
                You are very reliable, practical, and valuable to the people around you. At the same time, you are humble and easy-going. You have an analytical mind which helps you come to a situation critically. You are gifted with the forces of intolerance. It is hard to mislead you since you can see through such things. You also are doubtful to fall for a play of power, money, or position.
                <br></br>
                <br></br>
                <p className="playfair text-black text-xl md:text-3xl">Weaknesses</p><br/>
                Your perfectionism can get in your normal clear thought at times. You are distrustful of everything and everyone around you. Being too nosey is another fault of yours. Your nature of attempting to put everything in the correct order can be annoying to other people. You are responsible for uncertainty in broader issues, and this can become chronic, depending on molehills of minor problems into major ones. Everyone has soul strengths and pitfalls. What about yours? Get a thorough analysis of your powers and weaknesses with the use of Premium-quality Janampatri and also check your daily horoscope.  
                <br></br>
                <br></br>
                <p className="playfair text-black text-xl md:text-3xl">Eminent Personality</p><br/>
                Beyonce, Michael Jackson, Salma Hayek, Chris Pine, Richard Gere, Lea Michele, Jennifer Hudson, Zendaya, Nick Jonas, Bernie Sanders, Cameron Diaz, Niall Horan, Quvenzhane Wallis, Paul Walker, Kobe Bryant, Jimmy Fallon, Flo Rida, Blake Lively, Adam Sandler, Wiz Khalifa, Pink, Shania Twain, Sean Connery, Harry Connick Jr., Lilly Tomlin, Amy Poehler, William Howard Taft, Lyndon B. Johnson, Mary Shelley, Leo Tolstoy, Agatha Christie, Stephen King, Tom Ford, Karl Lagerfeld, Freddie Mercury, Keanu Reeves, Rupert Grint, Rishi Kapoor, Kareena Kapoor, ayushmann khuranaVivek Oberoi, Shakti Kapoor, Lucky Ali, Rakesh Roshan, Gulshan Grover, Shabana Azmi, Mahima Choudary.

             </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
